import authMixin from '@/mixins/auth-mixin'
import { mapState, mapActions, mapGetters } from 'vuex'
const CategoryContainer = () => import(/* webpackChunkName = "c-category-container" */ '@/components/pdf/CategoryContainer')
const CategoryForm = () => import(/* webpackChunkName = "c-category-form" */ '@/components/pdf/CategoryForm')

export default {
  name: 'Pdf',
  components: {
    CategoryContainer,
    CategoryForm
  },
  mixins: [authMixin],
  data () {
    return {
      visibleCategoryForm: false,
      selectedCategory: 'all' // Add this line
    }
  },
  created () {
    this.initPage()
  },
  computed: {
    ...mapState('pdf', ['categories']),
    ...mapGetters('user', ['token']),
    // Add new computed property for filtered categories
    filteredCategories () {
      if (this.selectedCategory === 'all') {
        return this.categories
      }
      return this.categories.filter(category => category.id === this.selectedCategory)
    }
  },
  methods: {
    ...mapActions('pdf', ['fetchCategories', 'deleteOldCategories']),
    initPage () {
      this.fetchCategories()
    },
    toggleAddForm () {
      this.visibleCategoryForm = !this.visibleCategoryForm
    },
    // Add new method to handle category selection
    selectCategory (categoryId) {
      this.selectedCategory = categoryId
    },
    handleSuccessSubmit () {
      this.toggleAddForm()
      this.$swal({
        icon: 'success',
        title: 'Success Add new Category',
        showConfirmButton: false,
        timer: 1500
      })
      this.fetchCategories()
    },
    handleRemoveCategory (id) {
      this.$swal({
        title: 'Apakah Anda Yakin?',
        text: 'Anda tidak akan dapat mengembalikan ini!',
        icon: 'warning',
        confirmButtonText: 'Yes, hapus!',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteOldCategories({
            categoryId: id,
            data: {
              token: this.token
            },
            success: this.successDeleteCategory
          })
        }
      })
    },
    successDeleteCategory () {
      this.$swal({
        icon: 'success',
        title: 'Success Delete Category',
        showConfirmButton: false,
        timer: 1500
      })
      this.fetchCategories()
    }
  }
}
